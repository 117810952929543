import axios from "axios";

export default {
    async $axios(options = {}) {
        return axios({
            url: process.env.VUE_APP_BASE_API + options.url,
            // method: 'post'
        });
    }
}
