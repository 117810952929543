<template>
  <div class="contain">
    <el-table :header-cell-style="{backgroundColor: '#DCF0FA',color:'#0896d9'}"
      v-loading="loading"
      style="width: 100%"
      :data="customerList"
      border
    >
      <!-- <el-table-column type="selection" width="55" align="center" /> -->
      <!--      <el-table-column label="id" align="center" prop="id" />-->
      <!-- <el-table-column label="序号" width="150" align="center" type="index" /> -->
      <!-- <el-table-column
        label="申请人姓名"
        align="center"
        prop="sqrxm"
        :show-overflow-tooltip="true"
        width="150"
      />
      <el-table-column
        label="当事人"
        align="center"
        prop="dsr"
        :show-overflow-tooltip="true"
        width="160"
      /> -->
      <el-table-column
        label="案件名称"
        align="center"
        prop="ajmc"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="当事人"
        align="center"
        prop="dsr"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="申请人"
        align="center"
        prop="sqrxm"
        :show-overflow-tooltip="true"
      />
      <!-- <el-table-column
        label="基本单位编码"
        align="center"
        prop="jbdwbm"
        :show-overflow-tooltip="true"
        width="200"
      />
      <el-table-column
        label="证件"
        align="center"
        prop="zj"
        :show-overflow-tooltip="true"
        width="300"
      /> -->
      <el-table-column
        label="申请时间"
        align="center"
        prop="sqsj"
        :show-overflow-tooltip="true"
      />
      <!-- <el-table-column
        label="处理状态"
        align="center"
        prop="clzt"
        :show-overflow-tooltip="true"
        width="300"
      /> -->
      <el-table-column
        label="手机号"
        align="center"
        prop="sqrybm"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="处理状态"
        align="center"
        prop="clzt"
        :show-overflow-tooltip="true"
      />
      <!-- <el-table-column
        label="法律文书"
        align="center"
        prop="laweragree"
        :show-overflow-tooltip="true"
      /> -->
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNo"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import cross from "../utils/cross";
import imgUrl from "../../public/avatar.png";
import Vue from "vue";
import qs from "qs";
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      // 遮罩层
      loading: true,
      customerList: [],
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        jcyId: "",
        clzt: "-1",
      },
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    this.getList();
    // this.getCookie();
  },
  methods: {
    getList() {
      const userString = localStorage.getItem("user");

      // 使用 JSON.parse() 将字符串转换回对象
      const user = JSON.parse(userString);
      this.queryParams.jcyId = user.jcyId;
      this.loading = true;
      let data = JSON.stringify(this.queryParams);
      let url =
        process.env.VUE_APP_BASE_API +
        "/f/userInterface/getCaseMarkingPapers?pageNo=" +
        this.queryParams.pageNo +
        "&pageSize=" +
        this.queryParams.pageSize +
        "&jcyId=" +
        this.queryParams.jcyId +
        "&clzt=" +
        this.queryParams.clzt;

      axios.post(url).then((res) => {
        this.customerList = res.data.list;
        this.total = res.data.count;
        this.loading = false;
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw * 1920 / 1920);
  height: calc(100vh * 1080 / 1080);
  background-image: url("../assets/login/login.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  .register-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw * 1230 / 1920);
    height: calc(100vh * 900 / 1080);
    .register-cont-title {
      width: calc(100vw * 1000 / 1920);
      margin-bottom: calc(100vh * 40 / 1080);
    }
    .register-cont-cont {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(100vw * 570 / 1920);
      height: calc(100vh * 440 / 1080);
      background-color: (rgba($color: #ffffff, $alpha: 0.7));
      border-radius: calc(100vw * 20 / 1920);
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .rcc-register {
        font-size: calc(100vw * 20 / 1920);
        margin-bottom: calc(100vh * 10 / 1080);
      }
      .rcc--ruleForm {
        width: calc(100vw * 460 / 1920);
      }
    }
  }
}
</style>
